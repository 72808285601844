import React from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"

// Components
import ContentWrapper from "./ContentWrapper"

// Styles
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Types
import { ILogos } from "../prismic-types/method"

interface IProps extends ILogos {
  className?: string
}

const LogoBanner: React.FC<IProps> = ({ label, logos, className }) => (
  <Container className={className}>
    <ContentWrapper>
      {label && <Label>{label}</Label>}
      <Logos>
        {logos.map((logo, index) => (
          <ImageWrapper key={`logo-banner-${index}`}>
            <GatsbyImage fluid={logo.url} alt={logo.alt} />
          </ImageWrapper>
        ))}
      </Logos>
    </ContentWrapper>
  </Container>
)

export default LogoBanner

const Container = styled.section`
  position: relative;
  padding: 40px 0;
  background-color: ${colors.skyBlueLight};
`

const Label = styled.p`
  ${textStyles.body};
  color: ${colors.blueGrey};
  margin-bottom: 12px;
  text-align: center;
`

const Logos = styled.div`
  display: flex;
  overflow-x: scroll;
  align-items: center;
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 128px;
  margin-right: 32px;

  img {
    margin-bottom: 0;
    filter: contrast(0.5);
    transform: scale(0.9);
  }

  &:last-of-type {
    margin-right: 0;
  }
`
