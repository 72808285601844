import React, { useEffect, useContext } from "react"
import styled from "styled-components"
import { scroller, Element } from "react-scroll"

// Components
import ContentWrapper from "./ContentWrapper"
import Check from "./icons/Checkmark"
import Button from "./atoms/Button"

// Constants
import { paths } from "../constants/paths"

// Utils
import AppContext from "../context/AppContext"

// Styling
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"
import colors, { shadows } from "../styles/colors"

// Types
import { IFeature, IFeatureColor } from "../prismic-types/features-page"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

interface IProps {
  features: IFeature[]
}

interface IColors {
  text: string
  accent: string
  background: string
}

const getColors: (color: IFeatureColor) => IColors = (color) => {
  switch (color) {
    case "red":
      return {
        text: colors.redDark,
        accent: colors.red,
        background: colors.red,
      }
    case "yellow":
      return {
        text: colors.yellowDark,
        accent: colors.yellowDark,
        background: colors.yellowSoft,
      }
    case "green":
      return {
        text: colors.greenDark,
        accent: colors.green,
        background: colors.greenSoft,
      }
    case "blue":
      return {
        text: colors.blueDark,
        accent: colors.blueLight,
        background: colors.blueLight,
      }
    default:
      return {
        text: colors.purpleLight,
        accent: colors.purpleLighter,
        background: colors.purpleLighter,
      }
  }
}

const Features: React.FC<IProps> = ({ features }) => {
  const context = useContext(AppContext)
  const { getLocalizedLink } = useLocale()

  useEffect(() => {
    if (context && context.scrollToAnchor !== null) {
      scroller.scrollTo(context.scrollToAnchor, {
        duration: 800,
        delay: 300,
        offset: -40,
        spy: true,
        smooth: true,
      })
      context.setScrollToAnchor(null)
    }
  }, [])

  return (
    <Container>
      {features.map((feature, index) => (
        <Element name={feature.label.toLowerCase()} key={`feature-${index}`}>
          <Feature key={`feature-${index}`}>
            <Header featureColors={getColors(feature.color)}>
              {feature.label}
            </Header>
            <TextWrapper>
              {(feature.title || feature.text) && (
                <TextBlock>
                  {feature.title && (
                    <Title
                      color={index === 0 ? colors.main["100"] : colors.black}
                    >
                      {feature.title}
                    </Title>
                  )}
                  {feature.text && (
                    <Text
                      color={colors.main["100"]}
                      fontSize="20px"
                      dangerouslySetInnerHTML={{ __html: feature.text }}
                    />
                  )}
                  {feature.buttonLabel && (
                    <StyledButton
                      variant="secondary"
                      label={feature.buttonLabel}
                      href={getLocalizedLink(`${paths.evaluation}`)}
                    />
                  )}
                </TextBlock>
              )}
              {feature.subjects.length > 0 && (
                <ItemsBlock>
                  {feature.subjects.map((item, i) => (
                    <Item key={`feature-item-${i}`}>
                      <ItemTitle featureColors={getColors(feature.color)}>
                        <StyledCheck>
                          <Check width={18} height={18} color={colors.white} />
                        </StyledCheck>
                        {item.title}
                      </ItemTitle>
                      <Text
                        color={index === 0 ? colors.main["100"] : colors.black}
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </Item>
                  ))}
                </ItemsBlock>
              )}
            </TextWrapper>
            {feature.media &&
              (feature.media.kind === "image" ||
                feature.media.kind === "video") && (
                <MediaWrapper color={getColors(feature.color).background}>
                  {feature.media.kind === "image" && (
                    <img alt={feature.label} src={feature.media.url} />
                  )}
                  {feature.media.kind === "video" && (
                    <Video controls>
                      <source src={feature.media.url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </Video>
                  )}
                </MediaWrapper>
              )}
          </Feature>
        </Element>
      ))}
    </Container>
  )
}

export default Features

const Container = styled.section`
  padding: 64px 0;
  background: ${colors.main["800"]};

  ${mq.from.M`
    padding: 120px 0;
  `}
`

const Feature = styled(ContentWrapper)`
  padding-bottom: 96px;

  ${mq.from.M`
    padding-bottom: 120px;
  `}
`

const Header = styled.div<{ featureColors: IColors }>`
  width: calc(100% - 48px);
  color: ${({ featureColors }) => featureColors.text};
  ${textStyles.subject};
  padding-bottom: 16px;
  margin: 0 24px 40px;
  border-bottom: 1px solid ${({ featureColors }) => featureColors.accent};

  ${mq.from.M`
    width: 100%;
    padding-bottom: 24px;
    margin: 0 0 72px;
  `}
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.from.M`
    flex-direction: row;
    column-gap: 24px;
  `}
`

const TextBlock = styled.div`
  padding: 0 24px;
  margin-bottom: 40px;

  ${mq.from.M`
    padding: 0;
    margin-bottom: 0;
    width: 50%;
  `}
`

const Title = styled.h3<{ color: string }>`
  ${textStyles.titleM};
  color: ${({ color }) => color};
  margin: 0 0 24px;

  ${mq.from.M`
    margin: 0 0 40px;
  `}
`

const Text = styled.div<{ color: string; fontSize?: string }>`
  ${textStyles.body};
  color: ${({ color }) => color};
  margin: 0;

  > p:last-child {
    margin-bottom: 0;
  }

  ${mq.from.M`
  > p {
    font-size: ${({ fontSize }: { fontSize: string }) =>
      fontSize || "18px"} !important;
  }
`}
`

const StyledButton = styled(Button)`
  margin-top: 32px;
`

const ItemsBlock = styled.ul`
  padding: 0 0 8px 24px;
  list-style: none;
  margin: 0 0 40px;
  display: flex;

  ${mq.to.M`
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    ::-webkit-scrollbar {
      /* Chrome/Safari/Webkit */
      display: none;
    }
  `}

  ${mq.from.M`
    padding: 0;
    margin: 0 0 24px;
    width: 50%;
    flex-wrap: wrap;
    justify-content: space-between;
  `}
`

const Item = styled.li`
  margin: 0 24px 0 0;
  min-width: 256px;

  ${mq.from.M`
    min-width: unset;
    width: calc(50% - 16px);
    margin: 0 0 72px 0;
  `}
`

const ItemTitle = styled.div<{ featureColors: IColors }>`
  color: ${({ featureColors }) => featureColors.text};
  ${textStyles.subject};
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  ${mq.to.M`
    white-space: nowrap;
  `}

  > div {
    background-color: ${({ featureColors }) => featureColors.accent};
  }
`

const StyledCheck = styled.div`
  margin-right: 8px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MediaWrapper = styled.div<{ color: string }>`
  border-radius: 8px;
  background-color: ${({ color }) => color};
  padding: 12px 12px 0 12px;
  overflow: hidden;
  margin: 0 24px;
  box-shadow: 0px 36px 80px rgba(54, 42, 102, 0.07),
    0px 15.0399px 33.4221px rgba(54, 42, 102, 0.0503198),
    0px 8.04107px 17.869px rgba(54, 42, 102, 0.0417275),
    0px 4.50776px 10.0172px rgba(54, 42, 102, 0.035),
    0px 2.39404px 5.32008px rgba(54, 42, 102, 0.0282725),
    0px 0.996212px 2.2138px rgba(54, 42, 102, 0.0196802);

  ${mq.from.S`
    border-radius: 24px;
    padding: 24px 24px 0 24px;
  `}

  ${mq.from.M`
    padding: 32px 32px 0 32px;
    margin: 0;
  `}

  img {
    margin: 1px 0 0;
    ${shadows.depthFive};
  }
`
const Video = styled.video`
  width: 100%;
  height: auto;
  border-radius: 8px;
  ${mq.from.S`
    border-radius: 24px;
  `}
`
